import Image from "next/image";
import { FC } from "react";

export const Logo: FC = () => {
	return (
		<div className="w-full max-w-[940px]">
			<div className="mr-[100px] pb-[30px] pl-[90px] pr-[90px] pt-[90px]">
				<Image src="/Algorythmic.svg" alt="Algorythmic logo" className="h-[36px]" height={36} width={288} />
			</div>
		</div>
	);
};

export default Logo;
