import { Footer, Header } from "components/layout";
import Head from "next/head";
import Link from "next/link";

export default function Home() {
	return (
		<>
			<Head>
				<title>Algorythmic - Home</title>
				<link rel="canonical" href="https://algorythmic.com/" />
				<meta property="og:title" content="Algorythmic - Home" />
				<meta property="og:url" content="https://algorythmic.com/" />
				<meta name="version" content="3.0.0" />
			</Head>
			<Header />
			<div className="w-full max-w-[940px]">
				{/* <div className="intro mr-[100px] pb-[30px] pl-[90px] pr-[90px] pt-[90px] text-[2.4em] font-[200] leading-[1.2]">
					<h2 className="text-[133%] font-bold">
						<b className="rounded bg-teal pb-[0.1em] pr-[0.1em] pt-[0] font-[600] text-orange">
							Hang tight, we&apos;re cleaning up in here...
						</b>
					</h2>
				</div> */}
				<div className="intro-two mr-[100px] pb-[1em] pl-[90px] pr-[90px] pt-[30px] text-[1.6em] font-[300] leading-[1.2]">
					Need to get in touch? Reach out at{" "}
					<Link
						className="underline-offset[0.15em] decoration-blue decoration-[0.09em]"
						href="mailto:team@algorythmic.com"
					>
						team@algorythmic.com
					</Link>
					<Footer />
				</div>
			</div>
		</>
	);
}
