import { FC } from "react";

export const Footer: FC = () => {
	return (
		<p className="mt-[3em]">
			<span className="text-[75%] text-grey">
				team@algorythmic.com | +01 720-706-4786 | &copy; Copyright {new Date().getFullYear()}, Algorythmic LLC
			</span>
		</p>
	);
};

export default Footer;
